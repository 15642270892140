import React, { useEffect, useState } from 'react';
import { Asset, EntrySkeletonType } from 'contentful'
import { getContentfulEntries, renderContentful } from '../../utils/contentful';
import GradientTitle from '../GradientTitle/GradientTitle';
import SplotchButton, { PaintItem, paintItems } from '../SplotchButton/SplotchButton';
import './BlogList.scss'
import Modal from '../Modal/Modal';
import PaletteItem from '../Intro/PaletteItem/PaletteItem';

export interface BlogListProps {
    anything?: string;
}


export interface BlogPost {
    titel: string;
    afbeelding?: Asset;
    inhoud: any;
    samenvatting: any;
    /** The paintItem each blogpost grabs its color from */
    paintItem: PaintItem;
}

const defaultAmountOfBlogs = 5;

const BlogList: React.FC<BlogListProps> = () => {
    const [showModal, setShowModal] = useState(false);
    const [blogPosts, setBlogPosts] = useState<Array<EntrySkeletonType<BlogPost>>>();
    const [clickedBlogPost, setClickedBlogPost] = useState<BlogPost>();
    const [displayAllBlogPosts, setDisplayAllBlogPosts] = useState(false);
    const blogPostsToDisplay = displayAllBlogPosts ? blogPosts : blogPosts?.slice(0, defaultAmountOfBlogs);

    useEffect(() => {
        const amountOfPaintItems = Object.keys(paintItems).length;

        getContentfulEntries({
            content_type: 'blogpost',
            order: '-sys.createdAt' as any
        }).then(entries => {
            const posts = entries.items;
            posts.forEach((post, i) => {
                const fields = post.fields as unknown as BlogPost;

                // Assign each blogpost a paintItem. Loop through the amount of paintItems
                fields.paintItem = paintItems[(i % amountOfPaintItems) + 1];
            })
            setBlogPosts(posts as any);
        })
    }, []);

    const displayReadMoreBtn = (post: EntrySkeletonType<BlogPost>) => <PaletteItem className="readmoreBtn" onClick={() => openModal(post.fields)}><SplotchButton paintItem={post.fields.paintItem} >Lees meer</SplotchButton></PaletteItem>


    const displayBlogPost = (post: EntrySkeletonType<BlogPost>) => {
        const thumbnail = post.fields.afbeelding?.fields?.file?.url;

        const textEl = <>
            <h3 className='h5'>{post.fields.titel}</h3>
            {renderContentful(post.fields.samenvatting)}
        </>;

        if (!thumbnail) {
            return <div className='float-wrapper'>
                {textEl}
                {displayReadMoreBtn(post)}
            </div>
        }
        return <>
            <div className='col-sm-3 d-flex align-items-center justify-content-center'>
                <img src={thumbnail as string} className="blogImg" />
            </div>
            <div className='float-wrapper col-sm-7'>
                {textEl}
                {displayReadMoreBtn(post)}
            </div>
        </>
    }


    const openModal = (blogPost: BlogPost) => {
        setClickedBlogPost(blogPost);
        setShowModal(true);
    }


    return (<>
        {showModal &&
            <Modal setShowModal={setShowModal} title={clickedBlogPost?.titel} bgColor={clickedBlogPost?.paintItem.bgColor} textTheme={clickedBlogPost?.paintItem.theme}>
                {renderContentful(clickedBlogPost?.inhoud)}
                Robert-Jan van der Wart<br />
                MfN registermediator en advocaat<br />
                <a href="mailto:rvanderwart@belangenatelier.nl">rvanderwart@belangenatelier.nl</a><br />
                <a href="tel:+31612580311">+31(0)612580311</a><br />
                <a href="https://linkedin.com/in/robertjanvanderwart">linkedin.com/in/robertjanvanderwart</a>
            </Modal>}

        <GradientTitle><h2 className='h1'>Blog</h2></GradientTitle>
        <div data-testid="BlogList">
            {blogPostsToDisplay && blogPostsToDisplay?.map((post) => <React.Fragment key={(post as any).sys.id}>
                <div className={`row blogListItem`}>
                    <span className='clearfix'></span>
                    <div className="row position-relative" >
                        {displayBlogPost(post)}
                    </div>
                </div>
            </React.Fragment>
            )}
            {(blogPosts?.length || 0) > defaultAmountOfBlogs ?
                <a className="display-all-blogs-button" onClick={() => setDisplayAllBlogPosts(!displayAllBlogPosts)}>{displayAllBlogPosts ? `Alleen de eerste ${defaultAmountOfBlogs} blogs weergeven` : `Alle blogs weergeven`}</a>
                : ''}
        </div>
    </>
    );
};

export default BlogList;



