import React, { useEffect, useState } from 'react';
import { EntrySkeletonType } from 'contentful';
import { getContentfulEntries, renderContentful } from '../../utils/contentful';
import './Contactgegevens.scss'


export interface Contactgegevens {
    titel: string;
    contactgegevens: Document;
}

export const contactgegevensTitle = 'Contactgegevens';

const Contactgegevens: React.FC = () => {
    const [contactgegevens, setContactgegevens] = useState<EntrySkeletonType<Contactgegevens>>();

    useEffect(() => {
        getContentfulEntries({ content_type: 'contactgegevens' }).then(entries => {
            const item = entries.items.find(item => item.fields.titel === contactgegevensTitle);
            if (!item) {
                return console.error(`No entry with the titel '${contactgegevensTitle}'`)
            }
            setContactgegevens(item as any)
        })
    }, []);

    return (
        <div
            className='contactgegevens'
            data-testid="Contactgegevens">
            {renderContentful(contactgegevens?.fields?.contactgegevens || '' as any)}
        </div>
    );
};

export default Contactgegevens;