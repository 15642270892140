import React, { PropsWithChildren } from 'react';
import styles from './GradientTitle.module.scss';
import { ThemeEnum } from '../../utils/theme';

export interface GradientTitleProps {
    theme?: ThemeEnum
}

const GradientTitle: React.FC<PropsWithChildren<GradientTitleProps>> = ({ children, theme }) => {
    return (
        <span className={`${styles.gradientText} ${theme === ThemeEnum.LIGHT && styles.gradientTextLight}`}>{children}</span>
    );
};

export default GradientTitle;