import React from 'react';

const PaletteItem: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children, ...props }) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            (props as any)?.onClick?.();
        }
    };
    return (
        <div
            tabIndex={0}
            onKeyDown={handleKeyDown}
            {...props}
            data-testid="PaletteItem">
            {children}
        </div>
    );
};

export default PaletteItem;