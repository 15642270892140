import React from 'react';
import './App.scss';
import BlogList from './components/BlogList/BlogList';
import Intro from './components/Intro/Intro';
import Divider from './components/Divider/Divider';
import './styles/bootstrap.scss';
import Contactgegevens from './components/Contactgegevens/Contactgegevens';
import ContactgegevensOnderaan from './components/ContactgegevensOnderaan/ContactgegevensOnderaan';


function App() {
  const urlParams = (new URL(window.location as any)).searchParams;
  const isTestVersie = urlParams.get('testVersie') as string | undefined;

  /** Als hij onder behandeling is, deze weergeven */
  const underConstruction = <center style={{ margin: '2rem' }}>We zijn nog bezig met de website, kom binnenkort weer eens kijken! <br />
    In de tussentijd kan er via <a href="tel:+31612580311">+31(0)612580311</a> of <a href="mailto:rvanderwart@belangenatelier.nl">rvanderwart@belangenatelier.nl</a> contact op worden genomen.</center>;

  return (
    <div className="App">
      <header className="App-header">
        <Contactgegevens />
      </header>
      <Intro></Intro>
      <main className='container'>
        {/* <Divider />
        <Matrix /> */}

        <BlogList></BlogList>

        <Divider />
        <ContactgegevensOnderaan />
      </main>
    </div>
  );
}

export default App;
