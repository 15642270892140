import React from 'react';
import styles from './Divider.module.scss';

export interface DividerProps {
    color?: string;
}
const Divider: React.FC<DividerProps> = ({ color }) => {
    return (
        <div className={styles.divider} data-testid="Divider">
            <div className={styles.dividerInner} style={{ backgroundColor: color }}></div>
        </div>

    );
};

export default Divider;