import React, { useEffect, useState } from 'react';
import './Intro.scss';
import { getContentfulEntries, renderContentful } from '../../utils/contentful';
import Modal from '../Modal/Modal';
import { PaintItem, logos, paintItems } from '../SplotchButton/SplotchButton';
import profilePic from '../../assets/images/Robert-Jan - blauw.png'
import PaletteItem from './PaletteItem/PaletteItem';


export interface PaletItem {
    paintItem: PaintItem;
    titel?: string;
    inhoud?: any;
    displayTitle: string;
    displayLogo?: boolean;
}

/**
 * These are the item's titles as set in the CMS. These are unique pages
 */
enum Onderdelen {
    "Business Mediation",
    "Over",
    "Maatwerk",
    // "Arbitrage",
    // "Bindend advies",
    "Voorwaarden en privacy",
    "Contact",
}

const Intro: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const [paletItems, setPaletItems] = useState<Record<keyof typeof Onderdelen, PaletItem>>({
        "Business Mediation": { displayTitle: `Business Mediation`, paintItem: paintItems[2], displayLogo: true },
        "Voorwaarden en privacy": { displayTitle: `Algemene Voorwaarden en Privacy Statement`, paintItem: paintItems[5] },
        Contact: { displayTitle: `Contact`, paintItem: paintItems[4] },
        Maatwerk: { displayTitle: `Exclusief maatwerk voor ondernemers`, paintItem: paintItems[3], displayLogo: true },
        Over: { displayTitle: `Over Het BelangenAtelier`, paintItem: paintItems[1], displayLogo: true }
    });
    const [clickedPaletItem, setClickedPaletItem] = useState<PaletItem>();

    useEffect(() => {
        getContentfulEntries({ content_type: 'paletItem' }).then(entries => {
            const items = { ...paletItems };

            Object.keys(Onderdelen)
                .filter(key => isNaN(key as any)) // filter out the enum numbers, only leaving the titles
                .forEach((k) => {
                    const key = k as keyof typeof Onderdelen;
                    const post = entries.items.find(item => (item.fields as unknown as PaletItem).titel === key);
                    if (!post) {
                        console.error(`Missing item with title: ${key}`);
                        return;
                    }
                    const fields = post.fields as unknown as PaletItem;
                    items[key] = { ...items[key], ...fields };
                })
            setPaletItems(items);
        });
    }, [])

    const getOnderdeel = (onderdeel: Onderdelen) => {
        if (!paletItems) return;
        const itemKey = Onderdelen[onderdeel] as keyof typeof Onderdelen;

        return paletItems[itemKey]
    }

    const openModal = (onderdeel: Onderdelen) => {
        const item = getOnderdeel(onderdeel);

        setClickedPaletItem(item);
        setShowModal(true);
    }

    const getTitle = (onderdeel: Onderdelen) => {
        return getOnderdeel(onderdeel)?.displayTitle
    }


    return (
        <>
            {showModal && <Modal setShowModal={setShowModal} title={clickedPaletItem?.displayTitle} bgColor={clickedPaletItem?.paintItem.bgColor} textTheme={clickedPaletItem?.paintItem.theme}>
                {clickedPaletItem?.titel === 'Contact' as any && <img src={profilePic} className='contactPageProfilePic' />
                }
                {renderContentful(clickedPaletItem?.inhoud)}
            </Modal>}
            <div data-testid="Intro">
                <div className="color-palette">
                    <PaletteItem
                        className={`palette-item palette-item--${paintItems[1].theme}`}
                        id="pi-over"
                        onClick={() => openModal(Onderdelen.Over)}>
                        <div className="color-swatch">
                            <div className="swatch-content" style={{ fontSize: '.9em' }}
                            >
                                <div className="swatch-heading">Over</div>
                                <img src={logos[paintItems[1].theme]} alt="Logo" className="swatch-image" />
                                <small>'Creatief uit conflicten'</small>
                            </div>
                            <div className="splotch" style={{ backgroundImage: `url(${paintItems[1].img})` }}></div>
                        </div>
                    </PaletteItem>
                    <PaletteItem
                        className={`palette-item palette-item--${paintItems[3].theme}`}
                        id="pi-maatwerk"
                        onClick={() => openModal(Onderdelen.Maatwerk)}>
                        <div className="color-swatch">
                            <div className="swatch-content">
                                <img src={logos[paintItems[3].theme]} alt="Logo" className="swatch-image" />
                                <div className="swatch-heading">
                                    {getTitle(Onderdelen.Maatwerk)}
                                </div>
                            </div>
                            <div className="splotch" style={{ backgroundImage: `url(${paintItems[3].img})` }}></div>
                        </div>
                    </PaletteItem>
                    <PaletteItem
                        className={`palette-item palette-item--${paintItems[2].theme}`}
                        id="pi-business-mediation"
                        onClick={() => openModal(Onderdelen["Business Mediation"])}>
                        <div className="color-swatch">
                            <div className="swatch-content">
                                <img src={logos[paintItems[2].theme]} alt="Logo" className="swatch-image" />
                                <div className="swatch-heading">
                                    {getTitle(Onderdelen['Business Mediation'])}
                                </div>
                            </div>
                            <div className="splotch" style={{ backgroundImage: `url(${paintItems[2].img})` }}></div>
                        </div>
                    </PaletteItem>
                    <PaletteItem
                        className={`palette-item palette-item--${paintItems[5].theme}`}
                        id="pi-voorwaarden-en-privacy"
                        onClick={() => openModal(Onderdelen["Voorwaarden en privacy"])}>
                        <div className="color-swatch">
                            <div className="swatch-content">
                                <div className="swatch-heading">
                                    {getTitle(Onderdelen['Voorwaarden en privacy'])}
                                </div>
                            </div>
                            <div className="splotch" style={{ backgroundImage: `url(${paintItems[5].img})` }}></div>
                        </div>
                    </PaletteItem>
                    <PaletteItem
                        className={`palette-item palette-item--${paintItems[4].theme}`}
                        id="pi-contact"
                        onClick={() => openModal(Onderdelen.Contact)}>
                        <div className="color-swatch">
                            <div className="swatch-content">
                                <div className="swatch-heading">{getTitle(Onderdelen.Contact)}</div>
                            </div>
                            <div className="splotch" style={{ backgroundImage: `url(${paintItems[4].img})` }}></div>
                        </div>
                    </PaletteItem>
                </div>

            </div>
        </>
    );
};

export default Intro;
