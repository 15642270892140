import React, { useEffect, useState } from 'react';
import { EntrySkeletonType } from 'contentful';
import { getContentfulEntries, renderContentful } from '../../utils/contentful';
import './ContactgegevensOnderaan.scss'


export interface ContactgegevensOnderaan {
    titel: string;
    contactgegevens: Document;
}

export const contactgegevensonderaanTitle = 'Tekst onderaan';

const ContactgegevensOnderaan: React.FC = () => {
    const [contactgegevensOnderaan, setContactgegevensOnderaan] = useState<EntrySkeletonType<ContactgegevensOnderaan>>();

    useEffect(() => {
        getContentfulEntries({ content_type: 'contactgegevens' }).then(entries => {
            const item = entries.items.find(item => item.fields.titel === contactgegevensonderaanTitle);
            if (!item) {
                return console.error(`No entry with the titel '${contactgegevensonderaanTitle}'`)
            }
            setContactgegevensOnderaan(item as any)
        })
    }, []);

    return (


        <div>
            {/* <div className="d-flex align-items-stretch"> */}
            {/* <div className="col-3">
                    <img src={profilePic} className='profilePic' />
                </div> 
                <div className="flex-grow-1" data-testid="ContactgegevensOnderaan">*/}
            <div data-testid="ContactgegevensOnderaan">
                {renderContentful(contactgegevensOnderaan?.fields?.contactgegevens || '' as any)}
            </div>
            <div>
                <div className='w-100 text-center'>
                    <strong className='h7'>Aangesloten bij</strong>
                </div>
                <div className="d-flex justify-content-center connected-to">
                    <a href="https://mfnregister.nl/">MFN-register</a>
                    <a href="https://www.mediatorsvereniging.nl/">Nederlandse Mediatorsvereniging</a>
                </div>
            </div>
            {/* </div> */}
        </div>
    );
};

export default ContactgegevensOnderaan;