import React, { PropsWithChildren } from 'react';
import './SplotchButton.scss'

import { getSplotchContrastColor } from '../../utils/getSplotchContrastColor';
import paintItem1 from "../../assets/images/palette/1--contrast_dark.svg";
import paintItem2 from "../../assets/images/palette/2--contrast_light.svg";
import paintItem3 from "../../assets/images/palette/3--contrast_dark.svg";
import paintItem4 from "../../assets/images/palette/4--contrast_light.svg";
import paintItem5 from "../../assets/images/palette/5--contrast_light.svg";

import lightLogo from '../../assets/images/BA-logo-wit.svg';
import darkLogo from '../../assets/images/BA-logo-zwart.svg';
import { getCustomPropertyColorHex } from '../../utils/getCustomPropertyColorHex';
import { ThemeEnum } from '../../utils/theme';

export interface PaintItem { img: string, bgColor: string; theme: ThemeEnum }
export const paintItems: Record<number, PaintItem> = {
    1: { img: paintItem1 } as any,
    2: { img: paintItem2 } as any,
    3: { img: paintItem3 } as any,
    4: { img: paintItem4 } as any,
    5: { img: paintItem5 } as any
};

(() => {
    Object.keys(paintItems).forEach((key) => {
        const item = paintItems[key as any];
        const img = item.img;
        const [, colorNumber] = img.match(/(\d+)--contrast_/) || [];
        if (!colorNumber) {
            console.error(`Could not find colorNumber for`, img)
        }
        paintItems[key as any] = {
            ...item,
            bgColor: getCustomPropertyColorHex(`--palette-color-${colorNumber}`),
            theme: getSplotchContrastColor(img)
        };
    });

})();

export const logos = {
    light: lightLogo,
    dark: darkLogo
}

export interface SplotchButtonProps {
    paintItem?: PaintItem;

}

const SplotchButton: React.FC<PropsWithChildren<SplotchButtonProps>> = ({ paintItem, children }) => {
    return (
        <>
            <a
                className={`button button--${paintItem?.theme} `}
                style={{ backgroundImage: `url(${paintItem?.img})` }}>{children}</a>
        </>
    );
};

export default SplotchButton;