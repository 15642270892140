import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './Modal.module.scss'
import './Modal.scss';
import GradientTitle from '../GradientTitle/GradientTitle';
import { ThemeEnum } from '../../utils/theme';

export interface ModalProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    title?: string;
    bgColor?: string;
    textTheme?: ThemeEnum;
}

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({ setShowModal, children, title, bgColor = '#f0f0f0', textTheme }) => {
    const backgroundPaint = (window as any).backgroundPaint;
    const displayingModalClass = 'displaying-modal'; // make sure this corresponds with the class in index.css
    function displayBehindPopupPaint(hexColor: string) {
        backgroundPaint.wave({ color: hexColor || "#f0f0f0", minSpeed: 7, maxSpeed: 13 });
    }

    function hideBehindPopupPaint() {
        // backgroundPaint.hide();

        backgroundPaint.clear();
        // backgroundPaint.display(); // we need it to display again so we can paint again
    }

    const [portalEl, setPortalEl] = useState<HTMLElement>();

    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeButtonRef = useRef<HTMLAnchorElement | null>(null); // Line 18: Added ref for the close button

    useEffect(() => {
        const portalElement = document.getElementById("modalPortal");
        if (!portalElement) return console.error(`No portal element`);

        displayBehindPopupPaint(bgColor);
        document.body.classList.add(displayingModalClass);

        setPortalEl(portalElement);
    }, [setPortalEl]);

    useEffect(() => {
        // Close modal on escape key
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        // Add event listener for escape key
        document.addEventListener('keydown', handleEscape);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, []);

    useEffect(() => {
        const getAllFocusableElements = () => modalRef.current?.querySelectorAll<HTMLElement>('a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select');
        const getFirstElement = () => getAllFocusableElements()?.[0];
        const getLastElement = () => {
            const els = getAllFocusableElements();
            if (!els?.length) return;

            return els[els.length - 1];
        }
        const focusFirstElement = () => getFirstElement()?.focus();
        const focusLastElement = () => getLastElement()?.focus();

        function trapTabKey(e: KeyboardEvent) {
            if (e.key === 'Tab') {
                if (e.shiftKey) /* shift + tab */ {
                    if (document.activeElement === getFirstElement()) {
                        e.preventDefault();
                        focusLastElement();
                    }
                } else /* tab */ {
                    if (document.activeElement === getLastElement()) {
                        e.preventDefault();
                        focusFirstElement();
                    }
                }
                // Only trap focus if the active element is within the modal
                if (modalRef.current && !modalRef.current.contains(document.activeElement)) {
                    e.preventDefault();
                    focusFirstElement();
                }
            }
        }

        document.addEventListener('keydown', trapTabKey);

        return () => {
            document.removeEventListener('keydown', trapTabKey);
        };
    }, []); // Empty dependency array to ensure this effect runs once on mount

    const onCloseClick = (e: React.MouseEvent<HTMLElement>): void => {
        if (e.target === modalRef.current) {
            setShowModal(false);
        }
    }

    const closeModal = () => {
        hideBehindPopupPaint();
        document.body.classList.remove(displayingModalClass);

        setShowModal(false);
    }

    return <>
        {portalEl && createPortal(
            <div className={`modalContainer ${styles.fallbackBackground} ${textTheme === ThemeEnum.LIGHT && styles.contentLight}`}
                style={{ backgroundImage: `linear-gradient(${bgColor} 0%, ${bgColor} 60%, transparent 70%)` }}
            >
                <div className={`${styles.modal} `}
                    ref={modalRef}>
                    <div className={styles.content}>
                        <a href="javacsript:void(0)" className={styles.closeButton} onClick={closeModal}>&times;</a>
                        <GradientTitle theme={textTheme}><h1>{title}</h1></GradientTitle>
                        {children}
                    </div>
                </div>
            </div>,
            portalEl
        )}
    </>
};

export default Modal;